(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/constants/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/components/tipsen/constants/assets/javascripts/constants.js');
"use strict";

const {
  breakpoints,
  BREAKPOINT_MD
} = svs.components.lbUtils.useMediaQuery;

const STATISTIC_SIDEBAR_QUERY = breakpoints.up(BREAKPOINT_MD);
setGlobal('svs.components.tipsen.constants', {
  STATISTIC_SIDEBAR_QUERY
});

 })(window);